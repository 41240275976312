import React from "react";
import "./styles/App.scss";
import QuoteInfo, { Inclusions, QuoteInfoProps } from "./components/QuoteInfo";

const cremationOnlyQuote: QuoteInfoProps = {
	title: "Cremation only",
	description:
		"Prepay for a cremation only, and when the time comes we'll simply return the ashes to your family or next of kin.",
	paymentOptions: [
		{ name: "fortnightly", amount: 23.53, paymentDuration: "5 years" },
		{ name: "monthly", amount: 118.71, paymentDuration: "2 years" },
		{ name: "upfront", amount: 2699 },
	],
	inclusions: [
		Inclusions.A,
		Inclusions.B,
		Inclusions.C,
		Inclusions.D,
		Inclusions.E,
		Inclusions.F,
		Inclusions.G,
	],
};

const cremationAndCelebrantQuote = {
	title: "Cremation + celebrant",
	description:
		"In addition to the cremation-only service, we'll provide your family with a professional funeral celebrant to plan and conduct a memorial service.",
	paymentOptions: [
		{ name: "fortnightly", amount: 30.45, paymentDuration: "5 years" },
		{ name: "monthly", amount: 156.21, paymentDuration: "2 years" },
		{ name: "upfront", amount: 3599 },
	],
	inclusions: [
		Inclusions.A,
		Inclusions.B,
		Inclusions.C,
		Inclusions.D,
		Inclusions.E,
		Inclusions.F,
		Inclusions.G,
		Inclusions.H,
		Inclusions.I,
	],
};

function App() {
	const quotes = [cremationOnlyQuote, cremationAndCelebrantQuote];
	return (
		<div className="app-container">
			<h1>Prepaid Cremation Service</h1>
			<p>
				A prepaid funeral enables you to pay for your preferred funeral
				arrangements at today’s prices. Once your funeral has been arranged and
				paid for, there are no further costs, and no further worries. It’s that
				simple.
			</p>
			<div className="quote-section">
				{quotes.map((quote) => (
					<QuoteInfo key={quote.title} {...quote} />
				))}
			</div>
		</div>
	);
}

export default App;
