import React from "react";
import "../styles/Switch.scss";

interface SwitchOption {
  id: number;
  name: string;
}

export interface SwitchProps {
  options: SwitchOption[];
  selectedOptionId: number;
  onSelect: (selectionId: number) => void;
}

function Switch(props: SwitchProps) {
  return (
    <div className="switch">
      {props.options.map((o) => (
        <button
          key={o.id}
          name={o.name}
          className={o.id === props.selectedOptionId ? "selected" : undefined}
          onClick={() => props.onSelect(o.id)}
        >
          {o.name}
        </button>
      ))}
    </div>
  );
}

export default Switch;
