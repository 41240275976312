const TickSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="12" fill="#B1E1D7"></circle>
		<line
			x1="6.81412"
			y1="14.7998"
			x2="8.79401"
			y2="16.7797"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
		></line>
		<line
			x1="9"
			y1="16.7796"
			x2="17.7681"
			y2="8.01151"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
		></line>
	</svg>
);

const CrossSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="11.5" fill="white" stroke="black"></circle>
		<path
			d="M8 8L16 16"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
		></path>
		<path
			d="M16 8L8 16"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
		></path>
	</svg>
);

const PdsSvg = () => (
	<svg
		width="20"
		height="25"
		viewBox="0 0 24 24"
		fill="white"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 13.1111C10.6779 13.1111 12.7507 13.1111 15.4286 13.1111M8 17.5556H15.4286M17.7143 22H6.28571C5.02335 22 4 21.0051 4 19.7778V4.22222C4 2.99492 5.02335 2 6.28571 2H12.6695C12.9726 2 13.2633 2.11707 13.4776 2.32543L19.6653 8.34123C19.8795 8.5496 20 8.83222 20 9.1269V19.7778C20 21.0051 18.9767 22 17.7143 22Z"
			stroke="#085D60"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		></path>
		<path
			d="M12 2V9H19"
			stroke="#085D60"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		></path>
	</svg>
);

const EmailSvg = () => (
	<svg
		width="20"
		height="25"
		viewBox="0 0 22 22"
		fill="white"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0)">
			<path
				d="M21.0635 4.4375H0.938477V18.4375H21.0635V4.4375Z"
				stroke="#085D60"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
			<path
				d="M0.938477 6.1875L11.001 12.75L21.0635 6.1875"
				stroke="#085D60"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
			<path
				d="M8.28838 10.9805L1.375 17.9993"
				stroke="#085D60"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
			<path
				d="M13.7139 10.9824L20.1879 17.9995"
				stroke="#085D60"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect
					width="21"
					height="21"
					fill="white"
					transform="translate(0.5 0.5)"
				></rect>
			</clipPath>
		</defs>
	</svg>
);

export { TickSvg, CrossSvg, PdsSvg, EmailSvg };
