import React from "react";
import Switch from "./Switch";
import "../styles/QuoteInfo.scss";
import { TickSvg, CrossSvg, EmailSvg, PdsSvg } from "./Svgs";

interface PaymentOption {
	name: string;
	amount: number;
	paymentDuration?: string;
}

export enum Inclusions {
	A = "Transfer from place of passing, 24/7",
	B = "Bioboard, Willow or MDF Coffin",
	C = "Filing necessary paperwork & permits",
	D = "A dignified, private cremation",
	E = "Return of your ashes, as directed by you",
	F = "Official death certificate",
	G = "3rd party trust to hold funds secure until needed",
	H = "Professional funeral celebrant",
	I = "Support planning the memorial service",
}

export interface QuoteInfoProps {
	title: string;
	description: string;
	paymentOptions: PaymentOption[];
	/** List of inclusions provided with the service */
	inclusions: Inclusions[];
}

export default function QuoteInfo(props: QuoteInfoProps) {
	const [selectedPaymentOption, setSelectedPaymentOption] = React.useState(
		props.paymentOptions[0]
	);

	return (
		<div className="quote-container">
			<div className="header-section">
				<h2>{props.title}</h2>
				<p>{props.description}</p>
			</div>
			<div className="payment-section">
				<Switch
					options={props.paymentOptions.map((p, idx) => ({
						id: idx,
						name: p.name,
					}))}
					selectedOptionId={props.paymentOptions.indexOf(selectedPaymentOption)}
					onSelect={(selectionId: number) =>
						setSelectedPaymentOption(props.paymentOptions[selectionId])
					}
				/>
				<div className="payment-details">
					<div className="payment-amount">${selectedPaymentOption.amount}</div>
					<div className="payment-duration">
						{selectedPaymentOption.paymentDuration
							? `over ${selectedPaymentOption.paymentDuration}`
							: ""}
					</div>
				</div>
			</div>
			<div className="inclusions">
				<h3>Included: </h3>
				<ul>
					{Object.values(Inclusions).map((i) => {
						return (
							<li key={i}>
								{Object.values(props.inclusions).includes(i) ? (
									<TickSvg />
								) : (
									<CrossSvg />
								)}
								<span>{i}</span>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="buttons-container">
				<a href="/">
					<PdsSvg />
					<span>View Product PDS</span>
				</a>
				<a href="/">
					<EmailSvg />
					<span>Email me my quote</span>
				</a>
				<button className="secondary" name="more info">
					Need more info?
				</button>
				<button className="primary" name="select and continue">
					Select & continue
				</button>
			</div>
		</div>
	);
}
